import React, { useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"
import HeaderDesktop from "./HeaderDesktop"
import HeaderMobile from "./HeaderMobile"
import { HeaderProps } from "@/app/interface/components"

const Header: React.FC<HeaderProps> = ({ setIsAlertModalOpen, vehicles }) => {
    const isDesktop = useMediaQuery({ minWidth: 1140 })
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        setIsMounted(true)
    }, [])

    // Renderiza nulo até que o componente esteja montado para evitar problemas de hidratação
    if (!isMounted) {
        return null
    }
    
    return (
        <>
            {
                isDesktop ? (
                    <HeaderDesktop setIsAlertModalOpen={setIsAlertModalOpen} vehicles={vehicles} />
                ) : (
                    <HeaderMobile setIsAlertModalOpen={setIsAlertModalOpen} vehicles={vehicles} />
                )
            }
        </>
    )
}

export default React.memo(Header)
import React from "react"
import CustomLink from "../../CustomLink"
import { ExploreContainerProps } from "@/app/interface/components"
import FreemodeSlide from "../../FreemodeSlide"

const ExploreContainer: React.FC<ExploreContainerProps> = ({styles, isExploreOpen, setIsExploreOpen}) => {
    return (
        <ul className={`${styles.explore}${isExploreOpen ? ' ' + styles.show : ''}`}>
            <FreemodeSlide gap="40px">
                <li>
                    <CustomLink href="/sobre" onClick={() => setIsExploreOpen(false)}>
                        <span>Sobre a BYD</span>
                        <div>
                            <img src={`${process.env.NEXT_PUBLIC_URL_IMG}/byd/general/header/about.webp`} width={400} height={220} loading="lazy" alt="" />
                        </div>
                    </CustomLink>
                </li>
                <li>
                    <CustomLink href="/consorcio" onClick={() => setIsExploreOpen(false)}>
                        <span>Consórcio BYD</span>
                        <div>
                            <img src={`${process.env.NEXT_PUBLIC_URL_IMG}/byd/general/header/consorcio.webp`} width={400} height={220} loading="lazy" alt="" />
                        </div>
                    </CustomLink>
                </li>
                {/*<li>
                    <CustomLink href="/pos-venda" onClick={() => setIsExploreOpen(false)}>
                        <span>Pós Venda BYD</span>
                        <div>
                            <img src={`${process.env.NEXT_PUBLIC_URL_IMG}/byd/general/header/pos-venda.webp`} width={400} height={220} loading="lazy" alt="" />
                        </div>
                    </CustomLink>
                </li>*/}
                {/*<li>
                    <CustomLink href="/blog" onClick={() => setIsExploreOpen(false)}>
                        <span>BLOG</span>
                            <div>
                                <img src=`${process.env.NEXT_PUBLIC_URL_IMG}/byd/general/blog.webp" width={400} height={220} loading="lazy" alt="" />
                            </div>
                    </CustomLink>
                </li>*/}
                <li>
                    <CustomLink target="_blank" href="https://www.seminovosvitoriamotorsbyd.com.br" onClick={() => setIsExploreOpen(false)}>
                        <span>Seminovos</span>
                        <div>
                            <img src={`${process.env.NEXT_PUBLIC_URL_IMG}/byd/general/header/seminovos.webp`} width={400} height={220} loading="lazy" alt="" />
                        </div>
                    </CustomLink>
                </li>
            </FreemodeSlide>
        </ul>
    )
}

export default React.memo(ExploreContainer)
import React, { createContext, useContext, ReactNode } from 'react'

interface BannerContextProps {
  contents: string[]
  defenseTypes: string[]
}

const BannerContext = createContext<BannerContextProps | undefined>(undefined)

export const useBannerContext = () => {
  const context = useContext(BannerContext)
  if (!context) {
    throw new Error('useBannerContext must be used within a BannerProvider')
  }
  return context
}

interface BannerProviderProps {
  children: ReactNode
  contents: string[]
  defenseTypes: string[]
}

export const BannerProvider: React.FC<BannerProviderProps> = ({ children, contents, defenseTypes }) => {
  return (
    <BannerContext.Provider value={{ contents, defenseTypes }}>
      {children}
    </BannerContext.Provider>
  )
}

import React from 'react'
import styles from '@/styles/Chatbot.module.scss'

interface ChatSelectProps {
  name: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  options: any[]
  placeholder: string
}

const ChatSelect: React.FC<ChatSelectProps> = ({ name, value, onChange, options, placeholder }) => (
  <div>
    <select className={styles.select} name={name} value={value} onChange={onChange} required>
      <option value=''>{placeholder}</option>
      {options.map((option: any, index: number) => (
        <option key={index} value={option.value || option.team || option}>
          {option.label || option.team || option}
        </option>
      ))}
    </select>
  </div>
)

export default ChatSelect

import validator from 'validator'

interface FormPayload {
    clientKey: string
    teamKey: string
    conversionGoal: string
    name: string
    email: string
    registrationNumber: string
    cellPhone: string
    message: string
    categoryId: number
    locationId: number
    hubUtmz: string
    productTitle?: string
}

function convertUTM(utmString: string): string {
    // Creating a map to rename the keys
    const keyMap: { [key: string]: string } = {
        'utm_source': 'utmcsr',
        'utm_medium': 'utmcmd',
        'utm_campaign': 'utmccn',
        'utm_content': 'utmcct',
        'utm_term': 'utmctm'
    }

    // Splitting the query string into key-value pairs
    const params = new URLSearchParams(utmString)

    // Filtering and renaming the keys
    const newParams = new URLSearchParams()
    params.forEach((value, key) => {
        if (keyMap[key]) {
        newParams.append(keyMap[key], value)
        }
    })

    // Building the new query string
    return newParams.toString()
}
  
const handleSubmitChatbot = async (
    body: any,
    landingPage: string,
    selectedCar: string,
    params: string,
    team: string
) => {

    const clientKey = ''
    const teamKey = ''
    const brandName = 'Vitória Motors'
    const conversionGoal = `[SITE - CHATBOT] ${brandName} ${landingPage.toUpperCase()}`
    const productTitle = selectedCar
    const name = body.name
    const email = body.email
    const registrationNumber = body.cpf
    const cellPhone = body.cellPhone
    const message = body.message
    const locationTitle = (body.unit === 'BYD Linhares' ? 'BYD Norte ES' : (body.unit === 'BYD Cachoeiro de Itapemirim' ? 'BYD Sul ES' : body.unit))
    let hubUtmz = ''
    if(params) hubUtmz = convertUTM(params)
    hubUtmz = hubUtmz?.replace(/&/g, "|") ?? ''

    const locationId = Number([
        { unit: 'BYD Vila Velha', id_unit: 13012 },
        { unit: 'BYD Serra', id_unit: 16651 },
        { unit: 'BYD Vitória', id_unit: 13013 },
        { unit: 'BYD Norte ES', id_unit: 13014 },
        { unit: 'BYD Sul ES', id_unit: 13015 },
        { unit: 'BYD Goiânia', id_unit: 13023 },
        { unit: 'BYD Goiânia - Flamboyant Shopping', id_unit: 14994 },
        { unit: 'BYD Rio Verde', id_unit: 13024 },
        { unit: 'BYD Belo Horizonte - Barão', id_unit: 13016 },
        { unit: 'BYD Belo Horizonte - Raja', id_unit: 16545 },
        { unit: 'BYD Juiz de Fora', id_unit: 13020 },
        { unit: 'BYD Uberlândia', id_unit: 13017 },
        { unit: 'BYD Ipatinga', id_unit: 13021 },
        { unit: 'BYD Montes Claros', id_unit: 13022 },
        { unit: 'BYD Uberaba', id_unit: 13019 },
        { unit: 'BYD Varginha', id_unit: 13018 },
        { unit: 'BYD Aparecida de Goiânia', id_unit: 14992 },
        { unit: 'BYD Anápolis', id_unit: 14993 },
        { unit: 'BYD Pouso Alegre', id_unit: 13745 },
        { unit: 'BYD Pouso Alegre - Shopping Serra Sul', id_unit: 14995 },
        { unit: 'BYD Shopping Del Rey', id_unit: 14996 },
        { unit: 'BYD Divinópolis', id_unit: 13746 },
        { unit: 'BYD Poços de Caldas', id_unit: 13744 },
        { unit: 'BYD Asa Norte - Brasília', id_unit: 14895 },
        { unit: 'BYD Pampulha', id_unit: 18094 },
        { unit: 'BYD - SIA', id_unit: 17599}
    ].find(data => data.unit === locationTitle)?.id_unit ?? '')

    if (
        !validator.isEmail(email) ||
        !name ||
        !registrationNumber ||
        !email ||
        !cellPhone ||
        !message
    ) {
        console.error("Preencha corretamente os campos.")
        return
    }

    const requestBody: FormPayload = {
        clientKey,
        teamKey,
        conversionGoal,
        name,
        email,
        registrationNumber,
        cellPhone,
        message,
        categoryId: 67259,
        locationId,
        hubUtmz,
        ...(productTitle && { productTitle })
    }

    try {
        const apiKey = process.env.NEXT_PUBLIC_API_KEY ?? ''
        const response = await fetch('https://api-pb-landingpage-prd.azurewebsites.net/api/post-data/integration/followize', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', 
                'x-api-key': apiKey,           
            },
            body: JSON.stringify({
                formPayload: requestBody,
                brand: 'byd',
                team
            }),
        })

        if (!response.ok) {
            throw new Error('Erro ao enviar os dados para a API.')
        }
    } catch (error) {
        if (error instanceof Error) {
            console.error('Erro:', error.message)
        } else {
            console.error('Um erro desconhecido ocorreu:', error)
        }
    }
}

export default handleSubmitChatbot
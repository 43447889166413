import React, { useState } from "react"
import CustomLink from "../../CustomLink"
import { ProductContainerProps } from "@/app/interface/components"
import FreemodeSlide from "../../FreemodeSlide"

const buttonData = [
    { name: 'Lançamentos', model: 'news', key: 'v' },
    { name: 'Elétricos', model: 'eletrics', key: 't' },
    { name: 'Híbridos', model: 'hybrids', key: 'u' }
]

const getModels = (model: string, news: any, eletrics: any, hybrids: any) => {
    switch (model) {
        case 'news': return news
        case 'eletrics': return eletrics
        case 'hybrids': return hybrids
        default: return []
    }
}

const ProductContainer: React.FC<ProductContainerProps> = ({ styles, isProductOpen, setIsProductOpen, news, eletrics, hybrids, setIsMenuOpen }) => {
    const [activeButton, setActiveButton] = useState<string>(buttonData[0].model)

    const handleButtonClick = (buttonName: string) => {
        setActiveButton(buttonName)
    }

    const renderBadge = (model: any) => {
        return (
            <>
                {/* Tag "Lançamento" aparece em menus que não sejam o de lançamentos */}
                {model.new && !model.soon && activeButton !== 'news' && <span className={styles.newCar}>Lançamento</span>}
                
                {model.soon && <span className={styles.soonCar}>Em breve</span>}

                {/* Tags "Elétrico" e "Híbrido" aparecem apenas no menu de lançamentos */}
                {activeButton === 'news' && model.model === "eletric" && <span className={styles.typeCar}>Elétrico</span>}
                {activeButton === 'news' && model.model === "hybrid" && <span className={styles.typeCar}>Híbrido</span>}
            </>
        )
    }

    const generateLinks = (models: any) => {
        return models.map((model: any, index: number) => {
            const normalizedModel = model.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ç/g, "c").replace(/\s+/g, "-").replace(".", "-").toLowerCase()
            const imgSrc = `${process.env.NEXT_PUBLIC_URL_IMG}/byd/general/header/cars/` + normalizedModel + '.webp'
            const url = `/${normalizedModel}`

            return (
                <div className={styles.card} key={index}>
                    <CustomLink onClick={() => {
                        setIsProductOpen(false)
                        setIsMenuOpen?.(false)
                    }} key={normalizedModel} href={model.new && model.soon ? `/solicitar-proposta/${normalizedModel}` : url} className={styles.models}>
                        <img src={imgSrc} alt="" width={120} height={120} loading="lazy" />  
                    </CustomLink>
                    <div>
                        <CustomLink onClick={() => {
                            setIsProductOpen(false)
                            setIsMenuOpen?.(false)
                        }} href={model.new && model.soon ? `/solicitar-proposta/${normalizedModel}` : url}>
                            <div>{renderBadge(model)}</div>
                            <span>BYD {model.name}</span>
                        </CustomLink>
                        <CustomLink onClick={() => {
                            setIsProductOpen(false)
                            setIsMenuOpen?.(false)
                        }} href={`/solicitar-proposta/${normalizedModel}`} className="btn-primary min fw600">Solicitar proposta</CustomLink>
                    </div>
                </div>
                
            )
        })
    }

    return (
        <div className={`${styles.products}${isProductOpen ? ' ' + styles.show : ''}`}>
            <div className={styles.productContent}>
                <FreemodeSlide gap="16px" padding="0px 16px">
                {buttonData.map(button => (
                    <button type="button" 
                        key={button.name}
                        className={`${styles.selectModel} ${activeButton === button.model ? styles.active : ''}`}
                        onClick={() => handleButtonClick(button.model)}
                    >
                        <span className="CMS">{button.key}</span> {button.name}
                    </button>
                ))}
            </FreemodeSlide>
            </div>

            <div className={styles.machines}>
                {generateLinks(getModels(activeButton, news, eletrics, hybrids))}
            </div>
        </div>
    )
}

export default React.memo(ProductContainer)

import styles from '@/styles/CookieConsent.module.scss'
import CustomLink from './CustomLink'
import React from "react"
import { CookieConsentProps } from '@/app/interface/components'

const CookieConsent: React.FC<CookieConsentProps> = ({onAccept, onDecline, buttonText, declineButtonText, text, cookieURL, hasCookie}) => {
    if(hasCookie) {
        return (
            <></>
        )
    } else {
        return (
            <div className={styles.main}>
                <div className={styles.modal}>
                    <p>{text} <CustomLink href={cookieURL}>Política de Cookies</CustomLink></p>
                    <div>
                        <button type="button" onClick={onAccept} className={styles.accept}>{buttonText}</button>
                        <button type="button" onClick={onDecline} className={styles.refuse}>{declineButtonText}</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default React.memo(CookieConsent)
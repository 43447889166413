import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

const useGoogleTagManager = () => {
  useEffect(() => {
    const gtmId = 'GTM-M9HBRCN'

    TagManager.initialize({ gtmId })
  }, [])
}

export default useGoogleTagManager


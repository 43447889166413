import { useCallback, useEffect, useState } from 'react'
import styles from "@/styles/FormSentModal.module.scss"
import { useTheme } from '@/app/contexts/ThemeContext'

function FormSentModal({ setIsFormSentModalOpen }: any) {
    const { theme } = useTheme()
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        // Marcando que o componente foi montado no cliente
        setIsClient(true)
    }, [])

    const handleClickOutside = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            setIsFormSentModalOpen(false)
        }
    }, [setIsFormSentModalOpen])

    useEffect(() => {
        // Add class to body to prevent scrolling
        document.body.classList.add("no-scroll")
        return () => {
            // Remove class from body when modal is closed
            document.body.classList.remove("no-scroll")
        }
    }, [])

    if (!isClient) {
        // Evita a renderização no servidor
        return null
    }

    return (
        <div className={styles.formSentModal} onClick={handleClickOutside}>
            <div className={styles.formSentModalContent}>
                <div className={styles.formSentModalHeader}>
                    <button type='button' onClick={() => setIsFormSentModalOpen(false)} aria-label="Fechar modal" title="Fechar modal">x</button>
                </div>
                <div className={styles.formSentModalMain}>
                    <img src={theme === 'dark' ? process.env.NEXT_PUBLIC_URL_IMG + '/byd/general/formSentDarkmode.svg' : process.env.NEXT_PUBLIC_URL_IMG + '/byd/general/formSent.svg'} alt="" width={306} height={250} />

                    <div className={styles.formSentModalMainText}>
                        <h2>Seus dados foram enviados com sucesso!</h2>
                        <p>Em breve um de nossos consultores entrará em contato.</p>
                    </div>
                    <button type='button' onClick={() => setIsFormSentModalOpen(false)}>Fechar</button>
                </div>
            </div>
        </div>
    )
}

export default FormSentModal

import { AlertBtnProps } from "@/app/interface/components"
import React, { useEffect, useState } from "react"

const AlertBtn: React.FC<AlertBtnProps> = ({ setIsAlertModalOpen, styles }) => {
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(true)
    }, [])

    if (!isClient) {
        return null
    }

    return (
        <div className={styles.alert}>
            <div>
                <button type="button" onClick={() => { 
                    setIsAlertModalOpen(true)
                }}>
                    alerta ao consumidor <span className="CMS">M</span>
                </button>
            </div>
        </div>
    )
}

export default React.memo(AlertBtn)

import React from 'react'
import { BiSend } from "react-icons/bi"
import styles from '@/styles/Chatbot.module.scss'

interface ChatInputProps {
  step: number
  field: string
  cpf: string
  cpfEdit: string
  phone: string
  phoneEdit: string
  handleKeyDown: (e: any) => void
  handleSendClick: () => void
  handleCpfChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleCellPhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  adjustHeight: () => void
  textAreaRef: React.RefObject<HTMLTextAreaElement>
  textInputRef: React.RefObject<HTMLInputElement>
}

const ChatInput: React.FC<ChatInputProps> = ({
  step,
  field,
  cpf,
  cpfEdit,
  phone,
  phoneEdit,
  handleKeyDown,
  handleSendClick,
  handleCpfChange,
  handleCellPhoneChange,
  adjustHeight,
  textAreaRef,
  textInputRef
}) => (
  <div className={styles.sendMessage}>
    {step !== 5 && step !== 6 && step !== 7 && step !== 10 && !field && (
      <div className={styles.inputContainer}>
        {step !== 4 && step !== 3 && (
          <textarea
            ref={textAreaRef}
            className={styles.userInput}
            onKeyDown={handleKeyDown}
            onInput={adjustHeight}
            rows={1}
            placeholder="Sua resposta..."
            autoFocus={step > 1 ? true : false}
          />
        )}
        {step === 4 && (
          <input 
            ref={textInputRef} 
            className={styles.userInput} 
            type="text" 
            placeholder="Sua resposta..." 
            onKeyDown={handleKeyDown}
            value={phone} 
            onChange={handleCellPhoneChange} 
            autoFocus={step > 1 ? true : false} 
          />
        )}
        {step === 3 && (
          <input 
            ref={textInputRef} 
            className={styles.userInput} 
            type="text" 
            placeholder="Sua resposta..." 
            onKeyDown={handleKeyDown} 
            value={cpf} 
            onChange={handleCpfChange} 
            autoFocus={step > 1 ? true : false} 
          />
        )}
        <BiSend onClick={handleSendClick} className={styles.sendIcon} size={40} />
      </div>
    )}

    {field && (
      <div className={styles.inputContainer}>
        {field.includes('Telefone') && (
          <input 
            ref={textInputRef} 
            className={styles.userInput} 
            type="text" 
            placeholder="Sua resposta..." 
            onKeyDown={handleKeyDown} 
            value={phoneEdit} 
            onChange={handleCellPhoneChange} 
            autoFocus={step > 1 ? true : false} 
          />
        )}

        {field.includes('CPF') && (
          <input 
            ref={textInputRef} 
            className={styles.userInput} 
            type="text" 
            placeholder="Sua resposta..." 
            onKeyDown={handleKeyDown} 
            value={cpfEdit} 
            onChange={handleCpfChange} 
            autoFocus={step > 1 ? true : false} 
          />
        )}

        {field.includes('Nome') && (
          <textarea
            ref={textAreaRef}
            className={styles.userInput}
            onKeyDown={handleKeyDown}
            onInput={adjustHeight}
            rows={1}
            placeholder="Sua resposta..."
            autoFocus={step > 1 ? true : false}
          />
        )}

        {field.includes('E-mail') && (
          <textarea
            ref={textAreaRef}
            className={styles.userInput}
            onKeyDown={handleKeyDown}
            onInput={adjustHeight}
            rows={1}
            placeholder="Sua resposta..."
            autoFocus={step > 1 ? true : false}
          />
        )}

        {field.includes('Assunto') && (
          <textarea
            ref={textAreaRef}
            className={styles.userInput}
            onKeyDown={handleKeyDown}
            onInput={adjustHeight}
            rows={1}
            placeholder="Sua resposta..."
            autoFocus={step > 1 ? true : false}
          />
        )}

        <BiSend onClick={handleSendClick} className={styles.sendIcon} size={40} />
      </div>
    )}
  </div>
)

export default ChatInput

import { ImageComponentHeightFixedProps } from "@/app/interface/components"
import React from "react"

const ImageComponentHeightFixed: React.FC<ImageComponentHeightFixedProps> = ({ src, height, alt, loading }) => {
    return (
      <div style={{ position: 'relative', width: '100%', height: `${height}px`, overflow: 'hidden' }}>
        <img
          src={src}
          alt={alt}
          height={height}
          style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', height: '100%', objectFit: 'cover' }}
          loading={loading}
        />
      </div>
    )
}

export default React.memo(ImageComponentHeightFixed)

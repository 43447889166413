import React, { useEffect, useRef, useState } from "react"
import styles from "@/styles/mobile/Header.module.scss"
import { useTheme } from "@/app/contexts/ThemeContext"
import ExploreContainer from "./ExploreContainer"
import ProductContainer from "./ProductContainer"
import { HeaderProps } from "@/app/interface/components"
import CustomLink from "../../CustomLink"
import { useRouter } from "next/router"

const HeaderMobile: React.FC<HeaderProps> = ({ setIsAlertModalOpen, vehicles }) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const [isExploreOpen, setIsExploreOpen] = useState<boolean>(false)
    const [isProductOpen, setIsProductOpen] = useState<boolean>(false)
    const { theme, toggleTheme } = useTheme()
    const menuRef = useRef<HTMLDivElement>(null)
    const router = useRouter()

    useEffect(() => {
        document.body.className = theme
    }, [theme])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsMenuOpen(false)
                setIsExploreOpen(false)
                setIsProductOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (isMenuOpen) {
            document.body.classList.add('no-scroll')
        } else {
            document.body.classList.remove('no-scroll')
        }
    }, [isMenuOpen])

    const altMode = theme === "light" ? "Ativar tema escuro" : "Ativar tema claro"

    const handleMenuClick = (menu: "explore" | "product") => {
        if (menu === "explore") {
            if (isProductOpen) {
                setIsProductOpen(false)
                setTimeout(() => {
                    setIsExploreOpen(true)
                }, 300) // Ajuste o tempo de acordo com a animação de fechamento
            } else {
                setIsExploreOpen(!isExploreOpen)
            }
        }

        if (menu === "product") {
            if (isExploreOpen) {
                setIsExploreOpen(false)
                setTimeout(() => {
                    setIsProductOpen(true)
                }, 300) // Ajuste o tempo de acordo com a animação de fechamento
            } else {
                setIsProductOpen(!isProductOpen)
            }
        }
    }

    return (
        <header className={styles.header}>
            <div className={styles.main} ref={menuRef}>
                <div className={styles.menu}>
                    <CustomLink href="/" onClick={() => setIsMenuOpen(false)}>
                        {router.pathname === "/" ? (
                            <h1 className={styles.logo + " Logo"} aria-label="BYD Vitória Motors" title="BYD Vitória Motors">BYD Vitória-Motors</h1>
                        ) : (
                            <span className={styles.logo + " Logo"} aria-label="BYD Vitória Motors" title="BYD Vitória Motors">BYD Vitória-Motors</span>
                        )}
                    </CustomLink>
                    <div className={styles.content}>
                        <button
                            type="button"
                            className={styles.mode + " CMS"}
                            onClick={toggleTheme}
                            aria-label={altMode}
                            title={altMode}
                        >
                            {theme === "light" ? "r" : "s"}
                        </button>
                        <button style={{textTransform: "lowercase"}} type="button" onClick={() => setIsMenuOpen(!isMenuOpen)} className="CMS">
                            {!isMenuOpen ? "a" : "b"}
                        </button>
                    </div>
                </div>

                <nav className={`${styles.menuOpen}${isMenuOpen ? ' ' + styles.show : ''}`}>
                    <button type="button" onClick={() => setIsAlertModalOpen(true)}>Alerta ao consumidor <span className="CMS">M</span></button>

                    <button type="button" onClick={() => handleMenuClick("explore")}>Explorar <span className={`CMS${isExploreOpen ? ' rotate' : ''}`}>b</span></button>
                    <ExploreContainer setIsMenuOpen={setIsMenuOpen} setIsExploreOpen={setIsExploreOpen} styles={styles} isExploreOpen={isExploreOpen} />

                    <button type="button" onClick={() => handleMenuClick("product")}>Produtos <span className={`CMS${isProductOpen ? ' rotate' : ''}`}>b</span></button>
                    <ProductContainer setIsProductOpen={setIsProductOpen} styles={styles} isProductOpen={isProductOpen} news={vehicles.news} eletrics={vehicles.eletrics} hybrids={vehicles.hybrids} setIsMenuOpen={setIsMenuOpen} />
                    <ul className={`${isProductOpen ? styles.contactOpen : styles.contact}`}>
                        <li>
                            <CustomLink onClick={() => setIsMenuOpen(!isMenuOpen)} className="btn-confirm" href="/solicitar-proposta">Solicitar Proposta</CustomLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default React.memo(HeaderMobile)

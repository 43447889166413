import React from 'react'
import styles from '@/styles/Chatbot.module.scss'

interface ChatHeaderProps {
  handleClose: () => void
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ handleClose }) => (
  <div className={styles.headerChatbot}>
    <div>
      <div className={styles.iconPeopleChat}>
        <img src={process.env.NEXT_PUBLIC_URL_IMG + '/byd/general/gabi.webp'} width={50} height={50} alt="" className={styles.iconPeople} />
      </div>
      <div>
        <h2>Gabi - BYD</h2>
        <p>Online agora</p>
      </div>
    </div>
    <button onClick={handleClose} className={styles.closeButton}>
      <img src={process.env.NEXT_PUBLIC_URL_IMG + '/byd/general/closeModalDarkmode.svg'} alt="Fechar modal" width={36} height={36} />
    </button>
  </div>
)

export default ChatHeader

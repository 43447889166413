

import React from "react"
import styles from "@/styles/Chatbot.module.scss"
import { BiChat } from "react-icons/bi"

const ChatbotIcon: React.FC<any> = ({ isChatbotOpen, setChatbotOpen }) => {
    return (
        <div className={`${styles.iconMessage} ${isChatbotOpen ? styles.open : ''} inicio-chatbot`} onClick={() => setChatbotOpen(true)}>
            <div className={styles.iconChat}>
                <BiChat size={36} className={styles.icon} />
            </div>        
        </div>
    )
}

export default React.memo(ChatbotIcon)
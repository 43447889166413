import { useEffect } from 'react'
import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

const SetCookiesOnFirstVisit = () => {
  useEffect(() => {
    const today = new Date().toDateString()
    const lastAccessed = Cookies.get('last_accessed')
    let token = Cookies.get('token')
    const params = new URLSearchParams(window.location.search)

    if(params.get('dealership')) {
      return
    }

    if (lastAccessed !== today) {
      if (!token) {
        token = uuidv4()
        Cookies.set('token', token, { sameSite: 'Lax', secure: true })
      }

      const utms = Cookies.get('utms') ? JSON.parse(Cookies.get('utms') || '[]') : []
      const utmParams = params.toString() ? params.toString() : ''
      utms.push(utmParams)

      let accessed_days = Number(Cookies.get('accessed_days')) || 1
      if(Cookies.get('accessed_days')) accessed_days++ 

      const accessed_dates = Cookies.get('accessed_dates') ? JSON.parse(Cookies.get('accessed_dates') || '[]') : []
      const access_date = new Date().toISOString().split('T')[0]
      accessed_dates.push(access_date)

      Cookies.set('utms', JSON.stringify(utms), { sameSite: 'Lax', secure: true })
      Cookies.set('accessed_days', accessed_days.toString(), { sameSite: 'Lax', secure: true })
      Cookies.set('accessed_dates', JSON.stringify(accessed_dates), { sameSite: 'Lax', secure: true })
      Cookies.set('last_accessed', today, { sameSite: 'Lax', secure: true })
    }
  }, [])

  return null
}

export default SetCookiesOnFirstVisit

import React, { useEffect, useRef, useState } from "react"
import AlertBtn from "./AlertBtn"
import styles from "@/styles/desktop/Header.module.scss"
import { useTheme } from "@/app/contexts/ThemeContext"
import { HeaderProps } from "@/app/interface/components"
import CustomLink from "../../CustomLink"
import { useRouter } from "next/router"
import ExploreContainer from "./ExploreContainer"
import ProductContainer from "./ProductContainer"

const HeaderDesktop: React.FC<HeaderProps> = ({ setIsAlertModalOpen, vehicles }) => {
    const { theme, toggleTheme } = useTheme()
    const menuContentRef = useRef<HTMLDivElement>(null)
    const [isSticky, setIsSticky] = useState(false)
    const [isExploreOpen, setIsExploreOpen] = useState<boolean>(false)
    const [isProductOpen, setIsProductOpen] = useState<boolean>(false)
    const [closingMenu, setClosingMenu] = useState<string | null>(null)
    const router = useRouter()
    
    const handleScroll = () => {
        const header = menuContentRef.current
        const sticky = header!.offsetTop

        if (window.scrollY > sticky) {
            setIsSticky(true)
        } else {
            setIsSticky(false)
        }
    }

    useEffect(() => {
        document.body.className = theme
    }, [theme])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuContentRef.current && !menuContentRef.current.contains(event.target as Node)) {
                setIsExploreOpen(false)
                setIsProductOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const altMode = theme === "light" ? "Ativar tema escuro" : "Ativar tema claro"

    const handleMenuClick = (menu: "explore" | "product") => {
        if (closingMenu) return

        if (menu === "explore") {
            if (isProductOpen) {
                setClosingMenu("product")
                setIsProductOpen(false)
                setTimeout(() => {
                    setIsExploreOpen(true)
                    setClosingMenu(null)
                }, 300) // Ajuste o tempo de acordo com a animação de fechamento
            } else {
                setIsExploreOpen(!isExploreOpen)
            }
        }

        if (menu === "product") {
            if (isExploreOpen) {
                setClosingMenu("explore")
                setIsExploreOpen(false)
                setTimeout(() => {
                    setIsProductOpen(true)
                    setClosingMenu(null)
                }, 300) // Ajuste o tempo de acordo com a animação de fechamento
            } else {
                setIsProductOpen(!isProductOpen)
            }
        }
    }

    return (
        <header className={styles.header}>
            <AlertBtn setIsAlertModalOpen={setIsAlertModalOpen} styles={styles} />
            <div className={`${styles.main}${isSticky ? ' ' + styles.headerFixed : ''}`} ref={menuContentRef}>
                <div className={styles.menu}>
                    <CustomLink href="/" onClick={() => {
                            setIsExploreOpen(false)
                            setIsProductOpen(false)
                        }}>
                        {router.pathname === "/" ? (
                            <h1 className={styles.logo + " Logo"} aria-label="BYD Vitória Motors" title="BYD Vitória Motors">BYD Vitória-Motors</h1>
                        ) : (
                            <span className={styles.logo + " Logo"} aria-label="BYD Vitória Motors" title="BYD Vitória Motors">BYD Vitória-Motors</span>
                        )}
                    </CustomLink>
                    
                    <div className={styles.midContent}>
                        <button type="button" onClick={() => handleMenuClick("explore")} className={isExploreOpen ? styles.active : ''}>Explorar</button>
                        <button type="button" onClick={() => handleMenuClick("product")} className={isProductOpen ? styles.active : ''}>Produtos</button> 
                    </div>
                    <div className={styles.rightContent}>
                        <button
                            type="button"
                            className={styles.mode + " CMS"}
                            onClick={toggleTheme}
                            aria-label={altMode}
                            title={altMode}
                        >
                            {theme === "light" ? "r" : "s"}
                        </button>
                        <CustomLink href="/solicitar-proposta" className="btn-primary black fw600" onClick={() => {
                            setIsExploreOpen(false)
                            setIsProductOpen(false)
                        }}>Solicitar proposta</CustomLink>
                    </div>
                </div>
                <nav className={styles.nav}>
                        <ExploreContainer setIsExploreOpen={setIsExploreOpen} styles={styles} isExploreOpen={isExploreOpen} />
                        <ProductContainer setIsProductOpen={setIsProductOpen} styles={styles} isProductOpen={isProductOpen} news={vehicles.news} eletrics={vehicles.eletrics} hybrids={vehicles.hybrids} />
                </nav>
            </div>
        </header>
    )
}

export default React.memo(HeaderDesktop)

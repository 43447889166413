import React, { useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"
import FooterDesktop from "./FooterDesktop"
import FooterMobile from "./FooterMobile"
import { useBannerContext } from "@/app/contexts/BannerContext"

const Footer: React.FC<any> = () => {
    const isDesktop = useMediaQuery({ minWidth: 1140 })
    const [isMounted, setIsMounted] = useState(false)
    const { contents, defenseTypes } = useBannerContext()

    useEffect(() => {
        setIsMounted(true)
    }, [])

    // Renderiza nulo até que o componente esteja montado para evitar problemas de hidratação
    if (!isMounted) {
        return null
    }
    
    return (
        <>
            {
                isDesktop ? (
                    <FooterDesktop contents={contents} defenseTypes={defenseTypes} />
                ) : (
                    <FooterMobile contents={contents} defenseTypes={defenseTypes} />
                )
            }
        </>
    )
}

export default React.memo(Footer)